import React from "react";
import "./home.css";
import Services from "../services/services";
import Portofolio from "../galery/portofolio";
import { Link } from "react-router-dom";
import Testimonials from "../testimonials/testimonials";
import CompanyValues from "../values/companyValue";
import Partners from "../values/parteners";
import { PrimaryButton } from "../shared/Button";
import h1 from "./H1.png";

function Home(props) {
  return (
    <div className="" style={{ backgroundColor: "#E6DED3" }}>
      <div className="container height-100 d-none d-md-flex flex-md-row  flex-sm-column  pt-5 align-items-center">
        <div className="d-flex flex-column align-items-md-center align-items-md-baseline pt-sm-5 mt-sm-5">
          <h1 className="text-uppercase">{props.dataHome["title"]}</h1>
          <p className="text-center text-md-left pt-2">
            {props.dataHome["messageHome"]}
          </p>
          <Link to="/contact" className="text-decoration-none">
            <PrimaryButton
              text={props.dataHome["btn"]}
              className="btn-margin"
            />
          </Link>
        </div>
        <div className="d-none d-md-flex w-50 ">
          <img src={h1} className="home-image" alt=''></img>
        </div>
      </div>
      <div className="mobile-back">
        <div className="d-flex align-items-center flex-column justify-content-center p-3">
          <h1 className="text-uppercase">{props.dataHome["title"]}</h1>
          <p className="text-center text-md-left pt-2">
            {props.dataHome["messageHome"]}
          </p>
          <Link to="/contact" className="text-decoration-none">
            <PrimaryButton
              text={props.dataHome["btn"]}
              className=""
            />
          </Link>
        </div>
      </div>
      <Services data={props.dataServices} />
      <CompanyValues data={props.companyValues} />
      <Portofolio data={props.dataHome} />
      <Testimonials data={props.dataReviews} />
      <Partners data={props.companyValues} />
    </div>
  );
}

export default Home;

import React, { useEffect } from "react";
import "./modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(
    () => {
      const handleEscape = (event) => {
        if (event.key === "Escape") {
          onClose();
        }
      };

      if (isOpen) {
        document.addEventListener("keydown", handleEscape);
      }
      return () => {
        document.removeEventListener("keydown", handleEscape);
      };
    },
    [isOpen,onClose]
  );
  if (!isOpen) return null;

  return (
    <div className="custom-modal">
      <div className="content">
 <FontAwesomeIcon
              icon={["fas", "times"]}
              className="close"
              size="sm"
              onClick={onClose}
              style={{position: 'absolute', right: '20px'}}
            />
        {children}
      </div>
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./portofolio.css";
import { PrimaryButton } from "../shared/Button";

function Portofolio(props) {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1800);
    });
  }, [scroll]);

  const images =  [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/main-page/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/main-page/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/main-page/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/main-page/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/main-page/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/main-page/6.jpg",

  ];

  return (
    <div className="mt-5 mb-5 pt-3">
      <div className="portofolio-gallery">
        {images.map((image) => {
          return (
            <img src={image} alt='' height={300}/>
          )
        })}
      </div>
      <Link to="/portofolio">
        <PrimaryButton text={props.data.viewPortofolio} className={"mb-3"}/>
      </Link>
    </div>
  );
}

export default Portofolio;

import React, { Component } from "react";
import { subscribe as SubscribeUser } from "../../services/contact.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      senderEmail: "",
    };
  }

  handleOnChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  subscribeUser = () => {
    var email = this.state.senderEmail;
    if (this.validateEmail(email)) {
      SubscribeUser(email);
    }
  };

  render() {
    return (
      <div className=" text-light bg-dark justify-content-center d-flex p-5">
        <div className="container ">
          <div className="row ">
            <div className="col ">
              <div className="row mt-3">
                <FontAwesomeIcon
                  icon={["fas", "map-marked"]}
                  size="1x"
                  className="mr-2"
                />
                Cannes, 1 <sup>re</sup> rue d'Antibes
              </div>
              <div className="row mt-3">
              <FontAwesomeIcon
                  icon={["fas", "envelope"]}
                  size="1x"
                  className="mr-2"
                /> info@citibatiment.com</div>
              <div className="row mt-3">
              <FontAwesomeIcon
                  icon={["fas", "phone"]}
                  size="1x"
                  className="mr-2"
                /> +33 6 18 24 32 11</div>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <div className="row">
                <h2>
                Citibatiment
                </h2>
                </div>
            </div>
            <div className="col m-auto">
              <div className="row mt-3 mb-3 d-flex justify-content-center">
                <a
                  href="https://www.facebook.com/citibatiment"
                  className="text-light mr-3">
                  <FontAwesomeIcon
                    icon={["fab", "facebook"]}
                    size="2x"
                    className="mr-2"
                  />
                </a>
                <a
                  href="https://www.instagram.com/citibatiment/"
                  className="text-light">
                  <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                </a>
              </div>
              <div className="row  d-flex align-items-center justify-content-center" >
                {/* <Link to="/contact">
                  <button className="">
                    Contact us
                    <FontAwesomeIcon
                      icon={["fas", "arrow-right"]}
                      className="mt-2 ml-3"
                      size="sm"
                    />
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-3">
            @All rights reserved
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
